/*
Script by : Clem
--------------------------------------------
||          Smooth anchor scroll          ||
--------------------------------------------

xxxxxxxx
Rien à modifier dans ce fichier.
*/


$(document).ready(function() {
	$('.js-scrollTo').on('click', function() { // Au clic sur un élément
		var page = $(this).attr('href'); // Page cible
		page = page.substring(page.indexOf("#"));
		var speed = 500; // Durée de l'animation (en ms)
		$('html, body').animate( { scrollTop: $(page).offset().top }, speed ); // Déplacement du scroll avec interpolation
		return false;
	});
});