/*
Script by : Clem
---------------------------------------
||             Scrollnav             ||
---------------------------------------

xxxxxxxx
Rien à modifier dans ce fichier.
*/


if ( $('.scrollnav').length ) {

	$('.scrollnav_link').each(function(i, obj) {

		var current_div = $(this);
		var link = $(this).parent().attr('href');
		var tolerance = 10;

		$(window).scroll(function (event) {

			target_top_distance_from_top = $(link).offset().top;
			target_height = $(link).height();
			target_bottom_distance_from_top = target_top_distance_from_top + target_height;
			window_distance_from_top = window.pageYOffset;
			// Tolérance
			target_top_distance_from_top = target_top_distance_from_top - tolerance;
			target_bottom_distance_from_top = target_bottom_distance_from_top + tolerance;
			
			if(target_top_distance_from_top <= window_distance_from_top-1 && window_distance_from_top <= target_bottom_distance_from_top) {
				current_div.addClass('active');
			} else {
				current_div.removeClass('active');
			}

		});

	});

}