/*
Script by : Clem
-------------------------------------------
||          Goto carousel slide          ||
-------------------------------------------

xxxxxxxx
Rien à modifier dans ce fichier.
*/


$('.goto-carousel-slide').on('click', function(e) {

	var target_modal = $(this).attr('data-target');
	var goto_slide = parseInt($(this).attr('goto-carousel-slide'));

	$(target_modal).find('.carousel').carousel(goto_slide);

});