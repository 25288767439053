/*
Script by : Clem
-----------------------------------------
||           Bootstrap nav++           ||
-----------------------------------------

xxxxxxxx
Rien à modifier dans ce fichier.
*/

// Ouverture des dropdowns si option true
if(navbar_dropdown_on_over == true) {
$('.nav-item.dropdown').hover(
	function() {
		$(this).find('[data-toggle=dropdown]').dropdown('toggle');
	}
);
}

/*$('.navbar-toggler').click(
	function() {
		if($('.navbar-collapse').hasClass('show')) {
			$('.navbar-collapse').css('transition', '0.5s');
			$('.navbar-collapse').css('transform', 'translateX(100%)');
		} else {
			$('.navbar-collapse').css('transition', '0.5s');
			$('.navbar-collapse').css('transform', 'translateX(0%)');
		}
	}
);*/

// Fermeture de la nav au clic
$(".navbar-nav a").click(function() {
  $('.collapse').collapse('hide');
});
