/*
Script by : Clem
*/

$(document).ready(function() {
	$('.fluidbloc').on('click', function() { 
		
		should_add_class = true;
		if($(this).hasClass('active')) {
			should_add_class = false;
		}

		$('.fluidbloc').each(function() {
			$(this).removeClass('active');
		});

		if(should_add_class) {
			$(this).addClass('active');
		}
	});

	$('.fluidbloc').on('mouseenter', function() { 

		$('.fluidbloc').each(function() {
			$(this).removeClass('active');
		});
		$(this).addClass('active');
	});

	/*$(".fluidbloc").on("click touchend", function(e) {
		should_add_class = true;
		if($(this).hasClass('active')) {
			should_add_class = false;
		}

		$('.fluidbloc').each(function() {
			$(this).removeClass('active');
		});

		if(should_add_class) {
			$(this).addClass('active');
		}
	});*/
});