
$(document).ready(function() {

	$('.check_modified_inputs .form-control').click(function() {
		if(typeof old_state == 'undefined') {
			old_state = $(this).val();
		}

		$(this).focusout(function() {
			new_state = $(this).val();

			if(old_state != new_state) {
				$(this).addClass('input_modified');
				$(this).parent().parent().find('.line_modified').val('1');
				delete old_state;
			}
		});
	});



});