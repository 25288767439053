function PhpSessionWrite(name,value) {

	$.ajax({
	type: 'GET',
	url: '/admin/php/ajax_php_session_write.php?'+name+'='+value,
	success: function(data) {
		if(debug_mode == true) { console.log('Variable de session définie : '+data); } },
	error: function() {
		console.log('Echec lors de la création de la variable de session : '+data); }
	});
};